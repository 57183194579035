<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang='ts'>
import {
  onMounted,
  ref,
  watch,
  nextTick,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isUserAuthenticated } from '@/core/guards/AuthGuard';
import { ROLES_NAME, ROLES } from '@/core/constants/role';
import { NotificationApp } from '@/shared/components/NotificationList.vue';
import authService from '@/modules/Auth/services/authService';
import HeaderDrawer from '@/shared/components/HeaderDrawer.vue';
import DetailTrip from '@/shared/components/DetailTrip/DetailTrip.vue';
import { Marker } from '@/core/types/GoogleMap';
import { TripDetail, ResponseTripLocation } from '@/core/types/TripDetail';
import TripsService from '@/shared/services/TripsService';
import ViewDetailIncident from '@/shared/components/ViewDetailIncident.vue';
import ViewDetailHumanCapital from '@/shared/components/ViewDetailHumanCapital.vue';
import IncidentsService from '@/shared/services/IncidentsService';
import NotificationsService from '@/shared/services/NotificationsService';
import { Incident } from '@/core/types/Incidents';
import { openDB } from 'idb';
import { AxiosResponse } from 'axios';
import { API_URL } from '../constants/urls.constants';

const renderComponent = ref(true);

const router = useRouter();
const route = useRoute();
const drawer = ref(false);
const drawerDetail = ref(false);

const isAuth = isUserAuthenticated();
const shortName = ref('');
const nameUser = ref('');
const role = ref(0);
const dialog = ref(false);
const windowWidth = ref(0);
const heightWindow = ref(0);
const widthDrawer = ref(0);
const titleHeader = ref('');

const tripId = ref(0);
const typeTrip = ref('');
const ordersReady = ref(false);
const selectedTrip = ref<TripDetail>();
// GEOLOCATION
const isLoadingLocation = ref(true);
const isErrorLocation = ref(false);
const isStatusLocation = ref(true);
const isErrorSnackbarLocation = ref(false);
const marker = ref<Marker>();
const saveMarker = ref<Marker>();

const haveIncidents = ref(true);
const haveEvidences = ref(true);

let notificationsCount = 0;

const notifications: NotificationApp[] = [];

const routesOfCoordinator = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeCoordinator',
  },
  {
    title: 'Viajes en curso',
    icon: 'mdi mdi-bus-clock',
    to: 'TripsList',
  },
  {
    title: 'Historial de viajes',
    icon: 'mdi mdi-history',
    to: 'HistoryTripsList',
  },
  {
    title: 'Comunicación',
    icon: 'mdi mdi-forum',
    to: 'SelectCommunication',
  },
];

const routesOfClient = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeClient',
  },
  {
    title: 'Historial de pedidos',
    icon: 'mdi mdi-history',
    to: 'HistoryOrdersList',
  },
  {
    title: 'Comunicación con coordinador',
    icon: 'mdi mdi-phone',
    to: 'SelectCoordinadorCommunication',
  },
];

const routesHumanCapital = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'homeHumanCapital',
  },
];

const routesOutbound = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'HomeOutbound',
  },
];

const routesOutboundCoordinator = [
  {
    title: 'Inicio',
    icon: 'mdi mdi-home',
    to: 'HomeOutboundCoordinator',
  },
];

if (isAuth) {
  nameUser.value = isAuth.name;
  [shortName.value] = nameUser.value.split(' ');
  role.value = localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 0;
} else if (localStorage.getItem('destinationCode') !== undefined) {
  role.value = 10;
} else {
  router.push({
    path: '/auth/role-selection',
    replace: true,
  });
}

const routesSidebar = ref();
const getRoutes = () => {
  if (role.value !== ROLES[0].id) {
    switch (role.value) {
      case 7: routesSidebar.value = routesHumanCapital; break;
      case 8: routesSidebar.value = routesOutbound; break;
      case 9: routesSidebar.value = routesOutboundCoordinator; break;
      default: routesSidebar.value = routesOfCoordinator; break;
    }
  } else {
    routesSidebar.value = routesOfClient;
  }
};

const select = () => {
  switch (route.path) {
    case '/home':
      titleHeader.value = `Hola ${nameUser.value.split(' ')[0]}`;
      break;
    case '/trips':
      titleHeader.value = 'Viajes en curso';
      break;
    case '/history-trips':
      titleHeader.value = 'Historial de viajes';
      break;
    case '/communication/3':
      titleHeader.value = 'Comunicación';
      break;
    case '/communication/1':
      titleHeader.value = 'Comunicación';
      break;
    case '/incidents':
      titleHeader.value = 'Incidentes';
      break;
    case '/detail-trip':
      titleHeader.value = 'Estado de pedido';
      break;
    case '/expired':
      titleHeader.value = 'Estado de pedido';
      break;
    default:
      titleHeader.value = `Hola ${nameUser.value.split(' ')[0]}`;
      break;
  }
};

const forceRerender = async () => {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
};

const updateAllRead = async () => {
  if (notificationsCount > 0) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    const userIdZam = userData.id_zam.toString();
    const db = await openDB(userIdZam, 2);
    const tx = db.transaction('notifications', 'readwrite');
    const store = tx.objectStore('notifications');

    let cursor = await store.openCursor();

    while (cursor) {
      // eslint-disable-next-line
      cursor.value['read'] = true;
      cursor.update(cursor.value);
      // eslint-disable-next-line
      cursor = await cursor.continue();
    }
    await tx.done;

    notifications.forEach((obj) => {
      // eslint-disable-next-line
      obj.read = true;
    });
    notificationsCount = 0;
    // update database
    NotificationsService.markAllAsSeen(Number(userIdZam))
      .catch((error) => {
        console.error(error);
      });
    await tx.done;
    forceRerender();
  }
};

const updateRead = async (key: string) => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const object = await store.get(key);
  // Modificar el objeto
  // eslint-disable-next-line
  object.read = true;
  notifications.find((x) => x.id === key).read = true;
  // Almacenar el objeto modificado
  await store.put(object, key);

  await tx.done;
  if (notificationsCount > 0) {
    notificationsCount -= 1;
  }
  // update database
  NotificationsService.markAsSeen(Number(key))
    .catch((error) => {
      console.error(error);
    });
  await tx.done;
  forceRerender();
};

// const handleServiceWorkerMessage = async (event : any) => {
//   if (event.data.type === 'NEW_NOTIFICATION') {
//     notificationsCount += 1;
//     const notification = {
//       id: notifications.length + 1,
//       type: event.data.data.type,
//       title: event.data.data.title,
//       text: event.data.data.body,
//       link: event.data.data.link,
//       file: event.data.data.file,
//       // eslint-disable-next-line
//       buttonText: (event.data.data.type === 'evidencesTrip') ? 'VER EVIDENCIAS' : (event.data.data.type === 'incident') ? 'VER INCIDENTE' : (event.data.data.type === 'tripReturn') ? 'VER MOTIVO' : (event.data.data.type === 'humanCapital' && event.data.data.link && event.data.data.link != '') ? 'VER MÁS' : '',
//       read: event.data.data.read,
//       tripId: event.data.data.tripId,
//       typeTrip: event.data.data.typeTrip,
//       incident_id: event.data.data.incident_id,
//       date: new Date().toLocaleDateString('es-MX'),
//     };
//     notifications.push(notification);
//     forceRerender();
//   }
// };

const initSSE = (userIdZam: string) => {
  const eventSource = new EventSource(`${API_URL}/v2/notifications/sse/user/${userIdZam}`);
  let incomingData;
  eventSource.onmessage = async (event) => {
    incomingData = JSON.parse(event.data);
    if (incomingData.id_zam.toString() === userIdZam) {
      incomingData.read = false;
      notificationsCount += 1;
      const notification = {
        id: incomingData.notification_id,
        type: incomingData.type,
        title: incomingData.title,
        text: incomingData.body,
        body: incomingData.body,
        link: incomingData.link,
        file: incomingData.file,
        // eslint-disable-next-line
        buttonText: (incomingData.type === 'evidencesTrip') ? 'VER EVIDENCIAS' : (incomingData.type === 'incident') ? 'VER INCIDENTE' : (incomingData.type === 'tripReturn') ? 'VER MOTIVO' : (incomingData.type === 'humanCapital'&& incomingData.link && incomingData.link != '') ? 'VER MÁS' : '',
        read: incomingData.read,
        tripId: incomingData.tripId,
        typeTrip: incomingData.typeTrip,
        incident_id: incomingData.incident_id,
        notification_id: incomingData.notification_id,
        date: new Date().toLocaleDateString('es-MX'),
      };
      notifications.push(notification);
      forceRerender();
      // Almacenar en la idb
      const db = await openDB(userIdZam, 2);
      const tx = db.transaction('notifications', 'readwrite');
      const store = tx.objectStore('notifications');
      // Leer y registrar todos los datos en la consola
      await store.put(notification, notification.id);
      await tx.done;
    }
  };
  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    eventSource.close();
    // Reintentar después de un cierto período de tiempo
    setTimeout(() => { initSSE(); }, 60000);
  };
};

const updateNotifications = async () => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  // Notificaciones
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const allData = await store.getAll();
  notificationsCount = 0;
  allData.forEach((obj) => {
    const notification = notifications.find((x) => x.id === obj.id);
    if (obj.read === false) {
      notificationsCount += 1;
    }
    if (notification) {
      notification.read = obj.read;
    }
  });
  await tx.done;
  forceRerender();
};

/* const updateNotificationsCount = async () => {
  const userData = JSON.parse(localStorage.getItem('userData') ?? '');
  const userIdZam = userData.id_zam.toString();
  // Notificaciones
  const db = await openDB(userIdZam, 2);
  const tx = db.transaction('notifications', 'readwrite');
  const store = tx.objectStore('notifications');
  const allData = await store.getAll();
  notificationsCount = 0;
  allData.forEach((obj) => {
    if (obj.read === false) {
      notificationsCount += 1;
    }
  });
  await tx.done;
  forceRerender();
}; */

onMounted(async () => {
  select();
  heightWindow.value = window.innerHeight;
  windowWidth.value = window.innerWidth;
  widthDrawer.value = heightWindow.value;
  window.addEventListener('resize', () => {
    heightWindow.value = window.innerHeight;
    windowWidth.value = window.innerWidth;
    widthDrawer.value = heightWindow.value;
  });

  watch(route, () => {
    select();
  });

  if (role.value > 0 && role.value <= 6) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    const userIdZam = userData.id_zam.toString();
    // Notificaciones
    const db = await openDB(userIdZam, 2, {
      // eslint-disable-next-line
      upgrade(db) {
        if (!db.objectStoreNames.contains('notifications')) {
          const objectStore = db.createObjectStore('notifications', { autoIncrement: false });
        }
      },
    });
    const tx = db.transaction('notifications', 'readwrite');
    const store = tx.objectStore('notifications');
    const allData = await store.getAll();
    allData.forEach((obj) => {
      if (obj.read === false) {
        notificationsCount += 1;
        const notification = {
          id: obj.notification_id,
          type: obj.type,
          title: obj.title,
          text: obj.body,
          link: obj.link,
          file: obj.file,
          // eslint-disable-next-line
          buttonText: (obj.type === 'evidencesTrip') ? 'VER EVIDENCIA' : (obj.type === 'incident') ? 'VER INCIDENTES' : (obj.type === 'tripReturn') ? 'VER MOTIVO' : (obj.type === 'humanCapital'&& obj.link && obj.link != '') ? 'VER MÁS' : '',
          read: obj.read,
          tripId: obj.tripId,
          typeTrip: obj.typeTrip,
          incident_id: obj.incident_id,
          notification_id: obj.notification_id,
          date: new Date().toLocaleDateString('es-MX'),
        };
        notifications.push(notification);
      }
    });
    await tx.done;
    forceRerender();
    initSSE(userIdZam);
  }
  getRoutes();
});

const logout = () => {
  if (role.value < 8 && localStorage.getItem('userData') !== null) {
    const userData = JSON.parse(localStorage.getItem('userData') ?? '');
    authService.logout(userData.id_zam);
  }
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('userData');
  // eslint-disable-next-line
  const routeRedirect = role.value === 8 ? '/auth/outbound' : role.value === 9 ? '/auth/outboundCoordinator' : `/auth/login/${role.value}`;
  router.push({
    path: routeRedirect,
    replace: true,
  });
};

const getLocationForTrip = async (id: number, isRefreshing?: boolean) => {
  saveMarker.value = {
    id: marker.value?.id || id,
    position: {
      lat: marker.value?.position.lat || 19.731509,
      lng: marker.value?.position.lng || -99.197767,
    },
    status: isStatusLocation.value,
  };
  isLoadingLocation.value = true;
  isErrorLocation.value = false;
  isErrorSnackbarLocation.value = false;
  marker.value = {
    id,
    position: {
      lat: 19.731509,
      lng: -99.197767,
    },
    status: false,
  };
  await TripsService.getTripLocation(saveMarker.value?.id || id)
    .then((response: AxiosResponse<ResponseTripLocation>) => {
      marker.value = isStatusLocation.value ? {
        id,
        position: {
          lat: 19.731509,
          lng: -99.197767,
        },
        status: false,
      } : {
        id: Number(response.data.tripId),
        position: {
          lat: Number(response.data.lat),
          lng: Number(response.data.lon),
        },
        status: false,
      };
      isErrorLocation.value = false;
    })
    .catch((error: any) => {
      console.log(error);
      if (isRefreshing) {
        isErrorSnackbarLocation.value = true;
        marker.value = saveMarker.value;
      } else {
        isErrorLocation.value = true;
      }
    })
    .finally(() => {
      isLoadingLocation.value = false;
    });
};

const getStatusForTrip = (id: number) => {
  haveIncidents.value = false;
  ordersReady.value = true;
  TripsService.getTripDetail(id)
    .then((response: any) => {
      selectedTrip.value = response.data;
      selectedTrip.value?.cptes.forEach((item) => {
        item.orders?.forEach((order) => {
          if (order.stateOrderDetail.length) {
            haveIncidents.value = order.stateOrderDetail[0]?.incidentes;
          }
        });
      });
      getLocationForTrip(id);
      ordersReady.value = false;
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const setSelectedTrip = (id: number, type: string) => {
  getStatusForTrip(id);
  typeTrip.value = type;
  tripId.value = id;
  drawerDetail.value = true;
};

const showIncidents = ref(false);
const incidentSelected = ref<Incident>();

const setIncident = (incidentId: number) => {
  IncidentsService.getIncidentById(incidentId).then((response) => {
    incidentSelected.value = response.data;
    showIncidents.value = !showIncidents.value;
  }).catch((error) => {
    console.error('ERROR -> GetIncidents', error);
  });
};

const showHumanCapital = ref(false);
let titleHumanCapital = ref<string>();
let descHumanCapital = ref<string>();
let dateHumanCapital = ref<string>();
let linkHumanCapital = ref<string>();
let fileHumanCapital = ref<string>();

const setHumanCapital = (title: string, description: string, date: string, link: string, file: string) => {
  titleHumanCapital = title;
  descHumanCapital = description;
  dateHumanCapital = date;
  linkHumanCapital = link;
  fileHumanCapital = file;
  showHumanCapital.value = !showIncidents.value;
};

</script>

<template>
  <v-theme-provider theme='myCustomTheme'>
    <v-layout>
      <v-app-bar :color="role === 8 || role === 9 ? 'outbound' : 'primaryHover'" prominent>
        <v-app-bar-nav-icon v-if="role!==10" variant='text' @click.stop='drawer = !drawer'></v-app-bar-nav-icon>
        <v-toolbar-title>
          <p class='body--bold--large text-left'>{{ titleHeader }}</p>
        </v-toolbar-title>
        <div class='d-flex justify-space-around'>
          <v-menu v-if='role!==5 && role!==4 && role!==9 && role!==7 && role!==8 && role!==10'>
            <template v-slot:activator='{ props }'>
              <v-badge v-if="renderComponent" color='success' :content="notificationsCount" class='badge-icon' id='badge-icon'>
                <v-icon icon='mdi mdi-bell' color='white' v-bind='props' v-on:click="updateNotifications()" v-on:keydown="updateNotifications()"></v-icon>
              </v-badge>
            </template>
            <v-list class='list-container' v-if="renderComponent" >
              <div class='list-container_header'>
                <h4 class='body&#45;&#45;bold&#45;&#45;medium'>Notificaciones</h4>
                <v-btn variant='text' color='primary' size="small" :disabled="!notificationsCount" v-on:click="updateAllRead()" v-on:keydown="updateAllRead()" class='list-container_headerbtn px-0'>Marcar todo como leído
                </v-btn>
              </div>
              <div>
                <span v-if='notificationsCount > 0'>
                  <v-list-item class='v-list' v-for='item in notifications.slice().reverse()' :key='item.id'>
                    <div v-if='item.read' class='list-notification'>
                      <div class='list-notification_container'>
                        <span class='list-notification_point'></span>
                        <v-icon icon='mdi-bell-alert-outline'></v-icon>
                        <v-chip v-if="item.type == 'humanCapital'" class='filter-chips__chip' variant='outlined'> CAPITAL HUMANO </v-chip>
                        <div v-if="item.type !== 'humanCapital'" class='list-notification_text'>
                          <h5 class='list-notification_title body--bold--base'>{{ item.title }}</h5>
                          <p class='list-notification_paragraph body--medium--medium'>{{ item.text }}</p>
                          <v-btn v-if="item.buttonText !== ''" v-on:click="(item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''" variant='flat' color='primary'>{{ item.buttonText }}</v-btn>
                        </div>
                      </div>
                      <div v-if="item.type == 'humanCapital'" class='list-notification_text list-notification-humanCapital'>
                        <h5 class='list-notification_title body--bold--base'>{{ item.title }}</h5>
                        <p class='list-notification_paragraph body--medium--medium'>{{ item.text }}</p>
                        <v-btn v-if="item.buttonText !== ''" v-on:click="(item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''" variant='flat' color='primary'>{{ item.buttonText }}</v-btn>
                      </div>
                    </div>
                    <div v-else v-on:click="updateRead(item.id)" v-on:keydown="updateRead(item.id)" class='list-notification-unread'>
                      <div class='list-notification-unread_container'>
                        <span class='list-notification-unread_point'></span>
                        <v-icon icon='mdi-bell-alert-outline'></v-icon>
                        <v-chip v-if="item.type == 'humanCapital'" class='filter-chips__chip' variant='outlined'> CAPITAL HUMANO </v-chip>
                        <div v-if="item.type !== 'humanCapital'" class='list-notification-unread_text'>
                          <h5 class='list-notification-unread_title body--bold--base'>{{ item.title }}</h5>
                          <P class='list-notification-unread_paragraph body--medium--medium'>{{ item.text }}</P>
                          <v-btn v-if="item.buttonText !== ''" v-on:click="(item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''" variant='flat' color='primary'>{{ item.buttonText }}</v-btn>
                        </div>
                      </div>
                      <div v-if="item.type == 'humanCapital'" class='list-notification-unread_text list-notification-humanCapital'>
                        <h5 class='list-notification-unread_title body--bold--base'>{{ item.title }}</h5>
                        <P class='list-notification-unread_paragraph body--medium--medium'>{{ item.text }}</P>
                        <v-btn v-if="item.buttonText !== ''" v-on:click="(item.type === 'evidencesTrip' || item.type === 'tripReturn') ? setSelectedTrip(item.tripId, item.typeTrip) : (item.type === 'incident') ? setIncident(item.incident_id) : (item.type === 'humanCapital') ? setHumanCapital(item.title, item.text, item.date, item.link, item.file) : ''" variant='flat' color='primary'>{{ item.buttonText }}</v-btn>
                      </div>
                    </div>
                  </v-list-item>
                </span>
                <div class='pl-6' v-else>
                  <h5>No hay notificaciones actualmente</h5>
                </div>
              </div>
            </v-list>
          </v-menu>
        </div>
        <img
          v-if='windowWidth > 767'
          class='mr-3'
          :src="`../../img/icons/${role === 8 || role === 9 ? 'delta-logo-white' : 'td-logo-blanco'}.svg`"
          alt='Logo Transdelta' />
        <img
          v-if='windowWidth <= 767'
          class='mr-3'
          :src="`../../img/icons/${role === 8 || role === 9 ? 'delta-icon-white' : 'td-icon-withe'}.svg`"
          alt='Icon Transdelta' />
      </v-app-bar>
      <v-navigation-drawer
        v-model='drawer'
        temporary
        app
        class='test-nav'
        :scrim='false'
        elevation='24'>
        <div class='text-left mb-2 px-5 test-nav__header-nav'>
          <p class='body--medium--large custom-title'>{{ nameUser || 'Usuario' }}</p>
          <p class='body--medium--medium'>{{ ROLES_NAME[role - 1] || 'Rol' }}</p>
          <v-btn
            @click='drawer = !drawer'
            class='float-button test-nav__header-nav__custom-button'
            density='compact'
            icon='mdi-arrow-left-drop-circle-outline'>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <v-list class='mt-1 text-left pl-0 d-flex flex-column nav-list-items' height='70vh'>
          <div>
            <v-list-item
              v-for="(item, index) in routesSidebar"
              :key="index"
              class="menu-items body--medium--medium"
              :prepend-icon="item.icon">
              <router-link :to="{ name: item.to }">{{ item.title }}</router-link>
            </v-list-item>
          </div>

          <div>
            <v-list-item class='menu-items body--medium--medium' prepend-icon='mdi mdi-account'>
              <router-link to='/my-account'>Mi cuenta</router-link>
            </v-list-item>
            <v-list-item v-if='role!==5 && role!==4 && role!==7 && role!==8 && role!==9' class='menu-items body--medium--medium' prepend-icon='mdi mdi-bell-ring'>
              <router-link to='/notifications'>Notificaciones</router-link>
            </v-list-item>
            <v-dialog v-model='dialog' persistent width='400'>
              <template v-slot:activator='{ props }'>
                <v-list-item
                  class='nav-icon menu-items body--medium--medium'
                  v-bind='props'
                  prepend-icon='mdi mdi-logout-variant'>
                  <p class='btn-logout'>Salir</p>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class='logout-title'>
                  <h4>Estás por cerrar sesión</h4>
                </v-card-title>
                <v-card-text class='logout-description'>
                  <p class='body--light--large'>¿Estás seguro que deseas salir?</p>
                </v-card-text>
                <v-card-actions class='logout-btn'>
                  <v-spacer></v-spacer>
                  <v-btn
                    :color="role === 8 || role === 9 ? 'outbound' : 'primary'"
                    class='mr-4 body--medium--medium'
                    @click='dialog = false'
                    variant='outlined'
                  >Cancelar
                  </v-btn>
                  <v-btn :color="role === 8 || role === 9 ? 'outbound' : 'primary'" variant="flat" class='btn-action body--medium--medium' @click='logout'> Salir</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer
        v-model='drawerDetail'
        temporary
        location='bottom'
        :scrim='false'
        touchless
        :width='widthDrawer -64'
        class='detail-trip-container'
      >
        <HeaderDrawer :on-click='() => drawerDetail = !drawerDetail'></HeaderDrawer>
        <DetailTrip :loadingDetail='ordersReady' :trip='selectedTrip' :marker='marker'
                    :typeTrip='typeTrip'
                    @find-location='(idTrip)=> getLocationForTrip(idTrip)'
                    @find-location-refreshing='(idTrip)=> getLocationForTrip(idTrip, true)'
                    :isErrorLocation='isErrorLocation'
                    :isLoadingLocation='isLoadingLocation'
                    :haveIncidents='haveIncidents'
                    :haveEvidences='haveEvidences'
        ></DetailTrip>
      </v-navigation-drawer>
      <v-dialog v-model='showIncidents' persistent width='600'>
        <ViewDetailIncident :incident='incidentSelected' :on-click='() => showIncidents = false'></ViewDetailIncident>
      </v-dialog>
      <v-dialog v-model='showHumanCapital' persistent width='600'>
        <ViewDetailHumanCapital :title='titleHumanCapital'
                                :description='descHumanCapital'
                                :date='dateHumanCapital'
                                :link='linkHumanCapital'
                                :file='fileHumanCapital'
                                :on-click='() => showHumanCapital = false'></ViewDetailHumanCapital>
      </v-dialog>
      <v-main>
        <slot></slot>
      </v-main>
    </v-layout>
  </v-theme-provider>
</template>

<style lang='scss'>
.v-chip{
  min-width: fit-content;
  overflow: hidden;
  color: rgb(var(--v-theme-primary)) !important;
}

.list-notification-humanCapital{
  padding: 0.5rem 5rem;
}

.nav-list-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout-title {
  margin: 2rem 1rem 0 1rem;
  padding: 0;
}

.logout-description {
  margin-bottom: 3rem;
}

.logout-btn {
  margin: 1.75rem 1rem;
}

.v-dialog > .v-overlay__content > .v-card > .v-card-text {
  padding: 0.25rem 1rem 0 1rem;
}

.btn-action {
  background: $primary;
  color: white;
}

.menu-items {
  .v-list-item__prepend > .v-icon {
    color: $secondaryLight;
    opacity: 1;
    margin-right: 0.5rem;
  }

  .v-list-item__content {
    padding-top: 0.2rem !important;

    a {
      color: $secondaryLight;
      text-decoration: none;
    }

    .btn-logout {
      color: $secondaryLight;
    }
  }

  &:hover {
    .v-list-item__prepend > .v-icon {
      color: $primaryHover;
    }

    background: $primaryTransparent;

    .v-list-item__content {
      a {
        color: $primaryHover;
      }

      .btn-logout {
        color: $primaryHover;
      }
    }
  }
}

.badge-icon {
  padding: 0 16px;
}

.nav-icon > .v-icon {
  margin-right: 0;
}

.custom-title {
  font-size: 1.2rem;
}

.custom-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}

.float-button {
  position: absolute;
  right: 0;
  top: 5%;
  margin-right: -14px;
}

.test-nav {
  padding: 0;
  min-width: 15vw;

  &__header-nav {
    padding: 20px 0;

    &__custom-button {
      opacity: 0;
      transition: all 200ms ease-in-out;
    }
  }

  &:hover {
    .test-nav__header-nav__custom-button {
      opacity: 1;
    }
  }
}

a {
  width: 100% !important;
  display: block;
}

.list-container {
  overflow-y: hidden;
  margin-top: 1rem;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    color: $secondaryLight;
    padding: 1rem 1.5rem;
  }

  &_headerbtn {
    text-transform: none;
  }

  .v-list {
    margin: 0px !important;
    padding: 0px !important;
  }
}
</style>
<style lang='scss'>
@import '../../scss/_variable.scss';

.list-notification {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 571px;
  background: $white;

  &_container {
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    color: $secondaryLight;
    padding: 0.5rem 1rem;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }

  &_point {
    background-color: transparent;
    width: 9.7px !important;
    height: 8px;
    border-radius: 40px;
    margin-top: 4px;
  }
}

.list-notification-unread {
  justify-content: center;
  align-items: center;
  max-width: 571px;
  background: #fff2f2;

  &_container {
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    color: $secondaryLight;
    padding: 0.5rem 1rem;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }

  &_point {
    background-color: #eb2626;
    width: 9.7px !important;
    height: 8px;
    border-radius: 40px;
    margin-top: 4px;
  }
}

.list-notification:hover {
  background: $backgroundLight;
}

.list-notification-unread:hover {
  background: #FAE1E1;
}
</style>
