import { RouteRecordRaw } from 'vue-router';

const OperationsChiefChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeOperationsChief',
    component: () => import('@/modules/Coordinator/views/HomeView.vue'),
  },
];

export default OperationsChiefChildrenRoutes;
